import { productHeader } from '../shared/product_header';
import PerfectScrollbar from 'perfect-scrollbar';

function isOverflown(element){
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

$(document).on('turbolinks:load', () => {
  if($('.shop_show_category_list').length) {
    const ps = new PerfectScrollbar('.shop_show_category_list', {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
  }
})


$(document).on('click', '.shop_show_item_card', function(e) {
  if(!$(e.currentTarget).data().itemId) { return false; }
  let target = $(e.currentTarget)
  console.log(target.data())
  if(target.data().type == 'basket_products' && target.data().menuUpsale) {
    $.get(`/basket_products/check_product_menu?item_id=${target.data().itemId}`).done((data) => {
      console.log('')
      if(data.length > 0) {
        $.ajax({
          method: 'POST',
          url: `/basket_products/ask_menus?item_id=${target.data().itemId}`,
          data: {menuIds: data}
        })
      } else {
        productHeader(target)
        $.get(`/basket_products/new?item_id=${target.data().itemId}`)    
      }
    })
  } else {
    productHeader($(e.currentTarget))
    $.get(`/${$(e.currentTarget).data().type}/new?item_id=${$(e.currentTarget).data().itemId}`)    
  }

})
