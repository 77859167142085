import { Controller } from 'stimulus';
import latinize from 'latinize';
import { productHeader } from '../shared/product_header';
import { stickyNav } from '../shared/sticky_nav'
import PerfectScrollbar from 'perfect-scrollbar';
import { flashMessage } from '../shared/notify';
import { convertColor } from '../shared/psbc';
export default class ShopController extends Controller {

  connect() {
    convertColor($(this.element).data().color)
    this.remaining = 60
    this.element['controller'] = this
    if($(this.element).data().hideIndex) {
      this.selectCategory($('.shop_category_item').eq(0).data().categoryId)
    }
    if($(this.element).data().timer) { this.launchCountDown() }
    //this.selectDistributionType()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  setTimer() {
    this.remaining = 60
  }

  setPrintTimer() {
    this.remaining = 10
  }

  selectDistributionType() {
    if($('.modal-backdrop').length == 0) {
      $('body').append('<div class="modal-backdrop fade show"></div>')
    }
    $('#modal-distribution-type').show()
  }

  launchCountDown() {
    $('#shop-countdown').text(this.remaining)
    this.interval = setInterval(() => {
      this.remaining -= 1
      console.log(this.remaining)
      $('#shop-countdown').text(this.remaining)
      if(this.remaining == 0) {
        this.giveUp()
      }
    }, 1000)
    $(document).on('click', () => {
      this.remaining = $('#bornes-modal-success:visible').length ? 10 : 60
    })
  }

  giveUp() {
    $('.loading_app_container').addClass('d-none')
    $.get('/cart/empty_cart').done(() => {
      clearInterval(this.interval)
      $('.modal').modal('hide')
      $('.modal .modal-content').html('')
      $('#bornes-cart').remove()
      $('#shop-main section').css('height', $(window).height() - 200)
      $('#shop-main aside').css('height', $(window).height() - 260)      
      Turbolinks.visit('/shops/splash_screen')
    })  
  }

  showItemDescription(e) {
    e.stopPropagation()
    let item = $(e.currentTarget).parents('.shop_show_item_card')
    $.get(`/shops/item_description?type=${item.data().type}&id=${item.data().itemId}`)
  }

  show_all() {
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="all"]`).addClass('active')
    $('.shop_show_section').toggle(true)
    $('.shop_show_items_container').removeClass('wrapping')
  }

  clickCategory(e) {
    this.selectCategory($(e.currentTarget).data().categoryId)
  }

  selectCategory(id) {
    this.show_all()
    $('#shop_show_search_input').val('')
    $('.show_all_items').removeClass('show_all_items')
    $('hr.shop-show__horizontal-separation').show()
    $('.shop-show__thumbnail-line').show()

    $('.shop_show_items_container').removeClass('wrapping')
    if(id != 'all') {
      $('.shop_shopw_section__see-all').hide()
      $('hr.shop-show__horizontal-separation').hide()
      $('.shop-show__thumbnail-line').hide()
      $('.shop_show_section').hide()

      let section = $(`#shop_section_${id}`)
      section.addClass('show_all_items')
      section.show()
      section.find('.shop_show_see_more').removeClass('d-flex')
      section.find('.shop_show_items_container').addClass('wrapping')
    } else {
      $('.shop_shopw_section__see-all').show()
      $('.shop_show_section').show()
      $('.shop_show_section').filter(function() {
        $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
      })
    }
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="${id}"]`).addClass('active')
    window.scrollTo(0, 0);
    if($(window).width() > 1200) {
      stickyNav(($(document).scrollTop() - 50) / $(window).height())
    }

  }

  search(e) {
    let value = latinize($('#shop_show_search_input').val().toLowerCase())
    if(value.length > 0) {$('.shop-show__thumbnail-line').hide()
      $('.shop-show__thumbnail-line').hide()
      window.scrollTo(0, 0);
      $('.shop_show_shop_card').hide(200)
      $('.shop_show_item_card').filter(function() {
        $(this).toggle(latinize($(this).data().filter.toLowerCase()).indexOf(value) > -1)
      });
    } else {
      $('.shop_show_shop_card').show()
      this.show_all()
    }

    $('.shop_show_section').filter(function() {
      $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
    })
  }

  showHours() {
    let url = `/shops/${$(this.element).data().id}/show_hours`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }

  showVitrine(e) {
    let url = `/shops/${$(this.element).data().id}/show_vitrine?vitrine_id=${$(e.currentTarget).data().id}`
    $.get(url)    
  }
}
