import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
import { productHeader } from '../shared/product_header';

var moment = require('moment');
export default class ShopcartController extends Controller {
  connect() {
    this.element['controller'] = this
    this.refresh() 
  }

  refresh() {
    if($(this.element).find('.bornes-cart__slide').length) {
      $(this.element).parent().show()
    } else {
      $(this.element).parent().hide()
    }
    this.currentSlide = 0
    this.setSlide()
    let initHeight = $('#bornes-cart').css('display') == 'none' ? 0 : $(this.element).parent().innerHeight()
    $('#shop-main section').css('height', $(window).height() - initHeight - 200)
    $('#shop-main aside').css('height', $(window).height() - initHeight - 260)
  }

  setSlide() {
    $('.bornes-cart__slide.active').removeClass('active')
    $('.bornes-cart__slide').eq(this.currentSlide).addClass('active')
    $('#bornes-cart__arrow-left').toggle(this.currentSlide != 0)
    $('#bornes-cart__arrow-right').toggle($('.bornes-cart__slide').eq(this.currentSlide + 1).length != 0)
  }

  prev() {
    this.currentSlide -= 1
    this.setSlide()
  }

  next() {
    this.currentSlide += 1
    this.setSlide()
  }

  edit(e) {
    let data = $(e.currentTarget).data()
    let smartId = data.smartId
    let cartId = data.cartId
    let type = data.type
    $('.loading_app_container').removeClass('d-none')
    productHeader($(`#shop_${type}_${smartId}`).eq(0))
    $.get(`/basket_${type}s/${cartId}/edit`)    
  }

  submit() {
    $('.loading_app_container').removeClass('d-none')
    $.get('/cart/reload')
  }
  
  toggleMinimize() {
    if($('#bornes-cart').hasClass('bornes-cart__main--minimized')) {
      $('#bornes-cart').removeClass('bornes-cart__main--minimized')
      $('#bornes-cart__dev-toggle span').text('Masquer')
    } else {
      $('#bornes-cart').addClass('bornes-cart__main--minimized')
      $('#bornes-cart__dev-toggle span').text('Afficher')

    }
    this.refresh()
    if($('#bornes-cart').hasClass('bornes-cart__main--minimized')) {
      $('#bornes-cart__arrow-right').hide()
      $('#bornes-cart__arrow-left').hide()      
    }
  }
}